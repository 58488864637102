import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

import { OutboundLink } from "gatsby-plugin-google-gtag"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Simple reservations, automated cooperation, reduced costs</SectionTitle>
      <center>
        <OutboundLink rel="noreferrer" target="_blank" href="https://docs.google.com/presentation/d/1LU3c_Ox3LhwOZv7dQ_oQ3iqXM-fTP9dpeLvanpFKSZ8/edit?usp=sharing">
        Ready-to-Go Onboarding Deck
      </OutboundLink>
      </center>
      
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Simplified Booking</FeatureTitle>
          <FeatureText>
            <InlineCode>/hotdesk tomorrow</InlineCode><br />
            or bring the whole team along with <InlineCode>/hotdesk thursday @fred @gemma</InlineCode>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Scheduled Bookings</FeatureTitle>
          <FeatureText>
            Designed for 2 or 3 days in the office<br />
            <InlineCode>/hotdesk every week on tuesday, wednesday</InlineCode>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Increase Cooperation</FeatureTitle>
          <FeatureText>
            If the office hits capacity, Simple Hot Desk will <strong>intelligently</strong> ask around to free up desk space.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Simple Administration</FeatureTitle>
          <FeatureText>
            Slack administrators can set booking limits, cancel bookings, bypass restrictions and manage employee schedules.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Reduce Office Costs</FeatureTitle>
          <FeatureText>
            Seamless capacity management reduces the need for space. With smart notifications to upsize or downsize.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Scaleup Friendly Pricing</FeatureTitle>
          <FeatureText>We charge based on the number of desks, with <strong>unlimited</strong> users. If you have 5 desks and 50 people, it's still free.</FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`

const InlineCode = styled.span`
  color: ${props => props.theme.color.primary};
  font-family: Courier New;
`
