import React from "react";
import {Helmet} from "react-helmet";

class CookieScript extends React.Component {
  render () {
    return (         
        <Helmet>  
          
          <script type="text/javascript" charset="UTF-8" src="//cdn.cookie-script.com/s/c7acc6d06eef180a9c139bf618d67e02.js"></script>
        </Helmet>
    );
  }
};

export default CookieScript