import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Contact = () => (
    <Section id="contact">
        <StyledContainer>
            <Subtitle>Questions?</Subtitle>
            <center><iframe width="560" height="315" src="https://www.youtube.com/embed/W7iNEiGVY4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
            <h2>Shoot us an email!</h2>
            <h3>hello@simplehotdesk.com</h3>
            </center>
        </StyledContainer>
    </Section>
);

export default Contact

const StyledContainer = styled(Container)``

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`